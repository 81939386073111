var 
	Access,
	_body,
	_window
;

(function($){

	Access = {

		$ : false,
		$main : null,
		components : [],
		$closeLibras : [],
		$openLibras : null,
		$zoomIn : null,
		$zoomOut : null,
		timeLibras : false,
		timeClose : false,

		init : function(){

			// Body
			if( _body === undefined ){
				_body = $( 'body' );
			} 

			// Window
			if( _window === undefined ){
				_window = $( window );
			}

			this.$main = $( '#pnbox-lnd' );

			this.$ = $( '#access', this.$main );
			this.$.hover(
				function(){
					if( Access.timeClose ) clearTimeout( Access.timeClose );
				}, 
				function(){
					Access.timeClose = setTimeout(function(){
						Access.close();
					}, 3000 );
				}
			);

			// Toogle
			this.$toggle = $( 'button[data-action="toggle-access"]', this.$ );
			this.$toggle.on( 'click', function(e){
				e.preventDefault();
				Access.toggle();
			});

			// Close
			this.$close = $( 'button.close', this.$ );
			this.$close.on( 'click', function(e){
				e.preventDefault();
				Access.close();
			});

			// Vlibras
			this.$openLibras = $( 'div[vw-access-button]' );
			this.$toggleLibras = $( 'button[data-action="toggle-vlibras"]', this.$ );
			this.$toggleLibras.on( 'click', '', function(){
				Access.toggleLibras();
			});

			// Zoom
			this.$zoomIn = $( '[data-action="zoom-in"]', this.$ );
			this.$zoomIn.on( 'click', function(e){
				e.preventDefault();
				Access.setZoom( 'more' );
			});
			this.$zoomOut = $( '[data-action="zoom-out"]', this.$ );
			this.$zoomOut.on( 'click', function(e){
				e.preventDefault();
				Access.setZoom( 'less' );
			});

			// Contrast
			this.$contrast = $( 'button[data-action="set-contraste"]', this.$ );
			this.$contrastBullets = $( '.bullets span', this.$ );
			this.$sheet01 = $( '#sheet-contrast-01' );
			this.$sheet02 = $( '#sheet-contrast-02' );
			this.$contrast.on( 'click', function(e){
				e.preventDefault();
				Access.changeContrast();
			});

			_window.on( 'click', function(e){

				e.target.$ = e.target.$ || $( e.target );

				// Close floater
				e.target.$f = e.target.$f || e.target.$.parents( '.floater-access' );
				if( 
					e.target.$.is( '[data-action="toggle-access"]' ) 
					|| 
					e.target.$.is( 'div[vw-access-button]' ) 
					|| 
					e.target.$.is( '.vpw-settings-btn-close' ) 
					|| 
					e.target.$.is( '.floater-access' ) 
					|| e.target.$f.length > 0 
				){
					return true;
				}
				else {
					Access.close();
				}

				// Vlibras
				if( e.target.$.is( 'vpw-settings-btn-close' ) ){

				}
				
			});
			
		},

		setZoom : function( action ){

			var zoom = parseInt( this.$main.css( 'font-size' ) );

			if( action == 'more' && zoom < 14 ){
				zoom += 2;
			}
			else if( action == 'less' && zoom > 10 ){
				zoom -= 2;
			}

			// Enable and disable
			this.$zoomIn.prop( 'disabled', ( zoom >= 14 ? true : false ) );
			this.$zoomOut.prop( 'disabled', ( zoom <= 10 ? true : false ) );
			
			// Set zoom
			this.$main.css( 'font-size', zoom + 'px' );

		},

		changeContrast : function(){

			this.$contrastBullets.removeClass( 'active' );

			if( this.$sheet01.prop( 'disabled' ) ){

				this.$sheet01.prop( 'disabled', false );
				this.$contrastBullets.eq( 1 ).addClass( 'active' );
			
			}
			else {
			
				if( this.$sheet02.prop( 'disabled' ) ){
					this.$sheet02.prop( 'disabled', false );
					this.$contrastBullets.last().addClass( 'active' );
				}
				else {
					this.$contrastBullets.first().addClass( 'active' );
					this.$sheet01.prop( 'disabled', true );
					this.$sheet02.prop( 'disabled', true );
				}
			
			}

		},

		toggleLibras : function(){
			if( Access.$toggleLibras.hasClass( 'active' ) ){
				Access.closeLibras();
			}
			else {
				Access.openLibras();			
			}
		},

		openLibras : function(){

			Access.$openLibras.trigger( 'click' );
			Access.$toggleLibras.addClass( 'active' );

			if( !Access.$closeLibras.length ){

				Access.$toggleLibras.prop( 'disabled', true );
				
				Access.waitVLibras(function(){

					console.info( "opa!" );

					Access.$toggleLibras.prop( 'disabled', false ).removeAttr( 'disabled' );
					
					// Bind close vlibras button
					Access.$closeLibras.on( 'click', function(){
						Access.$toggleLibras.removeClass( 'active' );
					});
				
				});

			}

		},

		waitVLibras : function( callback ){


			console.info( "waitVLibras" );
			

			if( !Access.$closeLibras.length ){

				Access.$closeLibras = $( '.vpw-settings-btn-close' );
				if( !Access.$closeLibras.length ){
				
					if( Access.timeLibras ) clearTimeout( Access.timeLibras );
					Access.timeLibras = setTimeout(function(){
						Access.waitVLibras( callback );
					}, 300 );
				
				}
				else {
					callback();
				}
			
			}
			else {
				callback();
			}
		
		},

		closeLibras : function(){
			this.$toggleLibras.removeClass( 'active' );
			$( '.vpw-settings-btn-close' ).trigger( 'click' );
		},

		toggle : function(){
			if( this.$.hasClass( 'opened' ) ){
				this.close();
			}
			else {
				this.open();
			}
		},

		open : function(){
			this.$.addClass( 'opened' );
		},

		close :  function(){
			this.$.removeClass( 'opened' );
		}

	};

	$(function(){
		Access.init();
	});

})(jQuery);

