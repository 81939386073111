function setDocHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty( '--vh', `${vh}px` );
};
window.addEventListener('resize', setDocHeight );
window.addEventListener('orientationchange', setDocHeight );
setDocHeight();

(function($){

    var _window;

    var PNBOX = {

        $ : null,
        $header  : null,
        $menu : null,
        $video : null,
        lastScrolTop : false,
        timeResize : false,
        currentMouseY : -1,
        lastGadget : false,

        init : function(){

            _window = $( window );

            PNBOX.$ = $( '#pnbox-lnd' );
            if( !PNBOX.$.length ) return true;

            PNBOX.$header = PNBOX.$.children( 'nav' ).first();
            PNBOX.$menu = $( '#menu', PNBOX.$ ).first();

            // Vídeo
            PNBOX.$video = $( '#video-wrapper' ).first();

            //  Binders
            PNBOX.bindHeader();
            PNBOX.bindSanfona();
            PNBOX.bindSlick();
            PNBOX.bindTools();
            PNBOX.bindFiles();
            PNBOX.bindResize();

            if( PNBOX.isMobile() ){
                PNBOX.mobileHeights();
            }

            $(window).resize(function() {
                PNBOX.bindResize();
            }).resize();
            
        },

        bindTools : function(){

            $( '#ferramentas .slider-tools' ).slick({
                slidesToScroll: 3,
                slidesToShow: 3,
                centerMode: true,
                centerPadding: '0',
                responsive: [
                    {
                        breakpoint: 1043,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            centerMode: false,
                        }
                    },
                    {
                        breakpoint: 721,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerMode: true,
                            centerPadding: '0',
                        }
                    },
                ]
            });

        },

        bindFiles : function(){

            $( '#arquivos .slider-files' ).slick({
                slidesToScroll: 5,
                slidesToShow: 5,
                centerMode: true,
                centerPadding: '0',
                responsive: [
                    {
                        breakpoint: 1161,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            centerMode: false,
                        }
                    },
                    {
                        breakpoint: 976,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            centerMode: true,
                        }
                    },
                    {
                        breakpoint: 759,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            centerMode: false,
                        }
                    },
                    {
                        breakpoint: 557,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerMode: true,
                        }
                    },
                ]
            });

        },

        bindResize : function(){
            if( PNBOX.timeResize ) clearTimeout( PNBOX.timeResize );
            setTimeout(function(){
                PNBOX.checkVideo();
            }, 50 );
        },

        checkVideo : function(){

            var vWidth = Math.max( document.documentElement.clientWidth, window.innerWidth || 0 );

            var gadget;
            if( vWidth >= 825 ){
                gadget = 'desktop';
            }
            else if( vWidth > 413 ){
                gadget = 'tablet';
            }
            else {
                gadget = 'mobile';
            }

            if( gadget !== PNBOX.lastGadget ){

                var _src = PNBOX.$video.data( 'source-' + gadget );

                PNBOX.$video.html('\
                    <video preload="auto" muted loop autoplay playsinline>\
                        <source type="video/webm" src="'+ _src +'.webm">\
                        <source type="video/mp4" src="'+ _src +'.mp4">\
                        Your browser does not support the video tag.\
                    </video>\
                ');

                PNBOX.lastGadget = gadget;

            }
            
        },

        mobileHeights : function(){
            var vHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        },

        scrollHeight : function(){
            return Math.max(
                document.body.scrollHeight, document.documentElement.scrollHeight,
                document.body.offsetHeight, document.documentElement.offsetHeight,
                document.body.clientHeight, document.documentElement.clientHeight
            );
        },

        // Binding header
        bindHeader : function(){
            // Close menu
            $( 'button', PNBOX.$menu ).on( 'click', function(){
                PNBOX.closeMenu();
            });
            $( '#menu a', PNBOX.$menu ).on( 'click', function(){
                PNBOX.closeMenu();
            });
        },

        // Toggle mobile menu
        toggleMenu : function(){
            if( PNBOX.$.hasClass( 'menu-opened' ) ){
                PNBOX.closeMenu();
            }
            else {
                PNBOX.openMenu();
            }
        },

        // Open menu mobile
        openMenu : function(){
            PNBOX.$menuToggle.addClass( 'opened' );
            PNBOX.$.addClass( 'menu-opened' );
        },

        // Close menu mobile
        closeMenu : function(){
            PNBOX.$menuToggle.removeClass( 'opened' );
            PNBOX.$.removeClass( 'menu-opened' );
        },

        bindSanfona : function(){

            // FAQ
            $( '.piki-sanfona' ).pikiSanfona({      
                items: '.sanfona-item',
                headers: '.item-header',
                contents: '.item-content',
                openFirst: false
            });

        },

        bindSlick : function(){

            var $slider  = $( '.slider' );
            var $nav = $( '#sldnav' );
            var $playPause = $( 'button.play-pause', $nav );

            // On init
            $slider.on( 'init', function( event, slick ){
                var $active = slick.$dots.children( 'li.slick-active' ).removeClass( 'slick-active' );
                setTimeout(function(){
                    $active.addClass( 'slick-active' );
                }, 1 );
            });
            $slider.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                fade: true,
                arrows: false,
                infinite: true,
                speed: 800,
                cssEase: 'linear',
                autoplay: true,
                autoplaySpeed: 10000,
                adaptiveHeight: true,
                appendDots: '#sldnav',
                pauseOnFocus: false,
                pauseOnHover: false,
                pauseOnDotsHover: false
            });

            $playPause.on( 'click', function(e){

                e.preventDefault();

                if( $nav.hasClass( 'paused' ) ){
                    $slider.slick( 'slickPlay' );
                    $nav.removeClass( 'paused' );
                }
                else {
                    $slider.slick( 'slickPause' );
                    $nav.addClass( 'paused' );
                }

            });
        
        },

        scrollTo : function( selector ){

            var $target = typeof( selector ) === 'object' ? selector : $( selector );            
            if( !$target.length ) return;

            var newY = $target.offset().top;
            var actualY = _window.scrollTop();
            var distance = actualY - newY;
            if( distance < 0 ) distance *= -1;

            var time = ( 600 - ( distance * 0.1 ) );
            if( time < 600 ) time = 600;
            
            var offset = $target.data( 'offset' );
            if( offset ){

                try {

                    var 
                        _json = JSON.parse( offset.split( "'" ).join( '"' ) )
                        _offset = false,
                        _vWidth = PNBOX.vWidth()
                    ;

                    $.each( _json.breaks, function( _break ){
                        if( _vWidth <= _break ){
                            _offset = parseInt( this );
                            return false;
                        }
                    });

                    offset = ( !_offset ) ? parseInt( _json.initial ) : _offset;

                }
                catch( e ){
                    var _offset = parseInt( offset );
                    offset = _offset > 0 ? _offset : 0;
                }

            }
            else {
                offset = 0;
            }

            $( 'html, body' ).animate({
                scrollTop: ( newY - ( offset + 30 ) )
            }, time );      

        },

        vWidth : function vWidth(){
            return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        },

        isMobile : function(){
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        }

    };
    $(function(){       
        PNBOX.init();
    });

})(jQuery);
