// instanciar Keycloak
var keycloak = new Keycloak({
    url: 'https://amei.homolog.kubernetes.sebrae.com.br/auth/',
    realm: 'externo',
    clientId: 'pnbox-frontend',
    userEmail: 'nome.pessoa@domin.io',
});

// No auth
var auth = false;

// conectar com o BaSeAccountProfile
var BaSeAccountProfile = document.querySelector( '#BaSeAccountProfile' );

// iniciar o Keycloak
keycloak
    .init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    })
    .then(function( authenticated ){
        auth = authenticated;
        if( keycloak.tokenParsed ){
            BaSeAccountProfile.authenticated = true;
            BaSeAccountProfile.userName = keycloak.tokenParsed.name;
            BaSeAccountProfile.userEmail = keycloak.tokenParsed.email;
            BaSeAccountProfile.clientUri = 'https://www.sebrae.com.br/pnbox';
        }
    })
    .catch(function( e ){
        console.linfo("🤯 Erro ao inicializar");
        console.log(e);
    })
;

BaSeAccountProfile.contextLinks = [
    { label: 'Meus Planos', url: 'https://pnbox.sebrae.com.br/planoNegocio' },
    { label: 'Ajuda e Feedback', url: 'https://pnbox.sebrae.com.br' },
    { label: 'Termos de Uso', url: 'https://pnbox.sebrae.com.br/termosDeUso' }
];

BaSeAccountProfile.keycloak = keycloak;

// redirecionar para o cadastro
function cadastroAmei(){

    if( !auth ){
        window.keycloak.login({
            action: "register",
            redirectUri: 'https://pnbox.sebrae.com.br'
        });
        return;
    }

    console.info( '👍 Usuário já está logado' );

}